import {
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
} from '@ionic/react';
import React,{useState} from 'react';
import './Gallery.css';

interface GalleryInterface{
    endpoint: string;
    setGraphic(name:string): void;
};
const getUrls = (endpoint:string,setGraphic:any) => {
    let params = new URLSearchParams({format: 'json'});
    return fetch(endpoint +'?'+ params)
        .then(resp=>resp.json())
        .then(resp=>{
            const columns = resp.map( (image:any)=>{
                return (
                    <IonCol size-lg={"3"} size-xs={"6"} key={image.name}>
                      <IonButton
                        className="thumb"
                        color="light"
                        onClick={(e)=>setGraphic(image.name)}>
                        <img src={image.thumbnail} alt={image.name}/>
                      </IonButton>
                    </IonCol>
                );
            });
            return columns;
        })
        .catch((e)=>console.log("GALLERY: ",e));
};
const Gallery = ({endpoint,setGraphic}:GalleryInterface) => {
    const [thumbs,setThumbs] = useState(<span>thumbs</span>);
    getUrls(endpoint,setGraphic)
        .then((thumbnails:any) =>{
            setThumbs(thumbnails);
        });
    return (
        <IonGrid>
          <IonRow>
            {thumbs}
          </IonRow>
        </IonGrid>
    );
};

export default Gallery;
