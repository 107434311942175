import {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonLabel,
    IonItem,
    IonButton,
} from '@ionic/react';
import React,{useState,useEffect} from 'react';
import Footer from './components/Footer';
import './Customize.css';
import Gallery from './components/Gallery';
const HOST = 'https://api.madeforusa.com';
const Customize: React.FC = () => {
    //eslint-disable-next-line
    const [shirt,setShirt] = useState('Cream');
    const [graphic,setGraphic] = useState('35mm_Camera_Black');
    const [location,setLocation] = useState("location");
    const [shirtUrl,setShirtUrl] = useState(HOST+'/shirt');
    useEffect(()=>{
        setShirtUrl(HOST+'/shirt?graphic='+graphic+'&shirt='+shirt+"&name="+location);
        console.log(shirtUrl);
    },[graphic,shirt,location]);
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons>
                <IonTitle>Y-Que</IonTitle>
                <IonButton href="/">
                  Home
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol size-xl="8" sixe-xs="6">
                    <Gallery
                      endpoint={HOST+"/graphics/"}
                      setGraphic={setGraphic}
                    ></Gallery>
                </IonCol>
                <IonCol size-xl="4" size-xs="6">
                  <div className="shirt-container">
                    <IonCard >
                      <IonCardHeader>
                        <img
                        id="shirt"
                        alt="Shirt"
                        src={shirtUrl}
                      />
                      </IonCardHeader>
                      <IonCardContent>
                        <IonItem lines="none">
                          <IonLabel>Location: </IonLabel>
                          <IonInput
                            type="text"
                            color="secondary"
                            value={location}
                            onIonChange={(e)=>setLocation(e.detail.value!)}
                          />
                        </IonItem>
                      </IonCardContent>
                    </IonCard>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
          <Footer/>
        </IonPage>
    );
};

//<IonLabel>{JSON.stringify(mapRef.location)}</IonLabel>
export default Customize;
