import {
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
} from '@ionic/react';
import React from 'react';
import GoogleMap from 'google-map-react';


interface MouseEvent{
    x: number;
    y: number;
    lat: number;
    lng: number;
    event: any;
};

interface Location{
    lat: number;
    lng: number;
};
interface MapDisplayInterface{
    setLoc(location:Location): void;
    location: Location;
    defaultLocation: Location;
    address: string;
};

const getOptions=(maps: any)=>{
    return {
        disableDefaultUI: false,
        zoomControl: true,
    };
};

const MapDisplay = ({setLoc,location,defaultLocation,address}:MapDisplayInterface) => {
    const defaultProps = {
        key: "AIzaSyCFSO0TxzAddB4_-eC1U0FqF-GSHS8t7i4",
        zoom: 12,
    };
    const mapState={
        location: location,
    };
    const onMapClick=(info: MouseEvent)=>{
        let lat=info.lat;
        let lng=info.lng;
        //console.log(lat,lng);
        const myLoc = {lat: lat, lng: lng};
        setLoc(myLoc);
    };
    const updateLocation=()=>{
        fetch('https://json.geoiplookup.io/api?')
            .then(resp=>resp.json())
            .then(resp=>{
                var myLoc={lat: resp.latitude, lng: resp.longitude};
                setLoc(myLoc);
            });
    };
    const key: string = "AIzaSyCFSO0TxzAddB4_-eC1U0FqF-GSHS8t7i4";
    return (
        <IonCard id="map">
          <GoogleMap
            bootstrapURLKeys={{ key: key}}
            center={mapState.location}
            defaultCenter={defaultLocation}
            defaultZoom={defaultProps.zoom}
            onClick={onMapClick}
            options={getOptions}
            onGoogleApiLoaded={updateLocation}
          ></GoogleMap>
        </IonCard>
    );
};

export default MapDisplay;
