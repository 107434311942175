import {
    IonItem,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonLabel,
    IonToolbar,
    IonIcon,
    IonButton,
    IonButtons,
    IonRouterLink,
    IonGrid,
    IonCol,
    IonRow,
    IonList,
} from '@ionic/react';
import {locate} from 'ionicons/icons';
import React,{useState,useEffect} from 'react';
import MapDisplay from './components/MapDisplay';
import Footer from './components/Footer';
import Geocode from "react-geocode";
import './Home.css';
const HOST = 'https://api.madeforusa.com';
Geocode.setApiKey("AIzaSyCFSO0TxzAddB4_-eC1U0FqF-GSHS8t7i4");

interface Locations{
    ordinances: Array<string>;
    locationClick(address:string): any;
};
const getShirtUrl=(name:string,graphic:string,shirt:string)=>{
    name = name.replace(' ','+');
    return HOST+'/shirt?name='+name+'&graphic='+graphic.toString()+'&shirt='+shirt.toString();
};

const LocationButtons = ({ordinances,locationClick}: Locations )=>{
    const buttons = ordinances.map(addr=>(
        <IonItem className="location-button-holder">
          <IonButton
            onClick={(e)=>locationClick(addr)}>
            {addr}
          </IonButton>
        </IonItem>
    ));
    return <IonList>{buttons}</IonList>;
};

const Home: React.FC = () => {
    //var mapRef = useRef(MapDisplay);
    //console.log(mapRef);
    const defaultLoc = {lat: -34.397, lng: 150.644,};
    const [location,setLoc] = useState(defaultLoc);
    const [address,setAddress] = useState('static/shirt.jpg');
    const [name,setName] = useState("nowhere");
    const [shirt,setShirt] = useState("");
    const [graphic,setGraphic] = useState("");
    const [ordinances,setOrdinances] = useState(["Nowhere"]);
    const getLocation=()=>{
        console.log("Locating");
        navigator.geolocation.getCurrentPosition(pos=>{
            let coords = pos.coords;
            let loc = {lat: coords.latitude, lng: coords.longitude};
            setLoc(loc);
        },console.log);
    };
    useEffect(()=>{
        Geocode.fromLatLng(location.lat.toString(),location.lng.toString())
            .then(resp=>resp.results)
            .then(res=>res.filter((addr:any)=>!addr.types.includes('postal_code')))
            .then(res=>res.map((addr:any)=>addr.address_components))
            .then(res=>res.flat(1))
            .then(res=>res.filter((addr:any)=>{
		let political=addr.types.includes('political');
		let country=addr.types.includes('country');
		let state=addr.types.includes('administrative_area_level_1');
		let county=addr.types.includes('administrative_area_level_2');
		return political && !(country||state||county);
            }))
            .then(res=>res.map((addr:any)=>addr.long_name))
            .then(res=>{
                res = [...new Set(res)];
                setOrdinances(res);
            });
    },[location]);
    useEffect(()=>{
        setAddress(getShirtUrl(name,graphic,shirt));
    },[name,shirt,graphic]);
    useEffect(()=>{
        setName(ordinances[0]);
    },[ordinances]);
    const locationClick = (addr:string)=>{
        setName(addr);
    };
    return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons>
                <IonTitle>Y-Que</IonTitle>
                <IonButton expand="block" fill="outline" color="primary" onClick={getLocation}>
                  <IonIcon icon={locate}></IonIcon>
                  &nbsp;Get Location 
        </IonButton>
	    <IonRouterLink href="/customize">
                <IonButton  expand="block" fill="solid" type="submit" color="danger">
                  Customize
        </IonButton>
	    </IonRouterLink>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonItem>
              <IonLabel>
                Please chose your desired location by clicking on the map and the nearby locations.
              </IonLabel>
            </IonItem>
            <IonGrid>
              <IonRow id="display-container">
                <IonCol size-xs={"12"} size-lg={"4"} className="hover-button">
                  <IonButton expand="block" fill="solid" color="primary" onClick={getLocation}>
                    <IonIcon icon={locate}></IonIcon>
                    &nbsp;Get Location 
                  </IonButton>

                  <MapDisplay
                    setLoc={setLoc}
                    location={location}
                    defaultLocation={defaultLoc}
                    address={address}
                  />
                </IonCol>
                <IonCol size-xs={"12"} size-lg={"3"}>
                  <LocationButtons ordinances={ordinances} locationClick={locationClick} />
                </IonCol>
            <IonCol id="shirt-img-container" size-xs={"12"} size-lg={"5"} className="hover-button">
	    <IonRouterLink href="/customize">
                  <IonButton expand="block" fill="solid" type="submit" color="danger">
                    Customize
        </IonButton>
	    	    </IonRouterLink>
                  <img id="shirt" alt="Shirt" src={address} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
          <Footer/>
        </IonPage>
    );
};
//<IonLabel>{JSON.stringify(mapRef.location)}</IonLabel>
export default Home;
